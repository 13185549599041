import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library, IconDefinition } from "@fortawesome/fontawesome-svg-core";

import {
  /* Misc and Logistics */
  faGripDotsVertical,
  /* Formatting */
  faSquareCheck as fasSquareCheck,
  faPenFancy,
} from "@fortawesome/pro-solid-svg-icons";

import {
  /* Misc and Logistics ----------------- */
  faPlusSquare,
  faCog,
  faEllipsis,
  faArrowsMaximize,
  faArrowUpRightFromSquare,
  faTrashAlt,
  faBookBookmark,
  faThumbtack,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faEye,
  faLowVision,
  faEyeSlash,
  faFilter,
  faSort,
  faTimes,
  faPlug,
  faSpinnerThird,
  faExternalLink,
  faCodeFork,
  faUser,
  faSquareCheck as falSquareCheck,
  faSquareX,
  faICursor,
  faChevronUp,
  faChevronDown,
  faChevronsDown,
  faChevronsLeft,
  faChevronsRight,
  faDiagramNested,
  faTerminal,
  //faUsers
  /* Actions --------------- */
  faPaperPlane,
  faCopy,
  faAt,
  faFeatherAlt,
  faRetweet,
  faImage,
  faLocationArrow,
  faPencil,
  faRotate,
  /* Tooling ------------------ */
  faMagnifyingGlass,
  faBell,
  faBellOn,
  faUsers,
  faReply,
  faReplyAll,
  faShare,
  faEnvelopeOpen,
  faForward,
  faBackward,
  faMap,
  /* Page Types ----------- */
  faBars,
  faInbox,
  faTableLayout,
  faChartSimple,
  faTimeline,
  faListTimeline,
  faTableList,
  /* Property Types */
  faFont,
  faLinkSimple,
  faCalendarClock,
  faHashtag,
  /* Message Types ---------------- */
  faMessageLines,
  faEnvelope,
  faEnvelopesBulk,
  /* Formatting -------- */
  faTextSlash,
  faBold,
  faItalic,
  faUnderline,
  faH1,
  faH2,
  faH3,
  faSquare,
  faListUl,
  faListOl,
  faListCheck,
  faSignature,
  faQuotes,
  faTableColumns,
  /* ---------- */
  faQuestion,
} from "@fortawesome/pro-light-svg-icons";

import {
  /* Message Types */
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

/* Misc and Logistics */
library.add(
  faPlusSquare,
  faCog,
  faEllipsis,
  faArrowsMaximize,
  faTrashAlt,
  faBookBookmark,
  faThumbtack,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faEye,
  faLowVision,
  faEyeSlash,
  faFilter,
  faSort,
  faTimes,
  faPlug,
  faGripDotsVertical,
  faSpinnerThird,
  faExternalLink,
  faCodeFork,
  faUser,
  falSquareCheck,
  faArrowUpRightFromSquare,
  faSquareX,
  faQuestion,
  faICursor,
  faPenFancy,
  faChevronUp,
  faChevronDown,
  faChevronsDown,
  faChevronsLeft,
  faChevronsRight,
  faDiagramNested,
  faTerminal
);
/* Actions */
library.add(
  faPaperPlane,
  faCopy,
  faAt,
  faFeatherAlt,
  faRetweet,
  faImage,
  faLocationArrow,
  faPencil,
  faRotate
);
/* Tooling */
library.add(
  faMagnifyingGlass,
  faBell,
  faBellOn,
  faUsers,
  faReply,
  faReplyAll,
  faShare,
  faEnvelopeOpen,
  faForward,
  faBackward,
  faMap
);
/* Property Types */
library.add(faFont, faLinkSimple, faCalendarClock, faHashtag);
/* Page Types */
library.add(
  faBars,
  faInbox,
  faTableLayout,
  faChartSimple,
  faTimeline,
  faListTimeline,
  faTableList
);
/* Message Types */
library.add(faTwitter, faMessageLines, faEnvelope, faEnvelopesBulk);
/* Formatting */
library.add(
  faTextSlash,
  faBold,
  faItalic,
  faUnderline,
  faH1,
  faH2,
  faH3,
  faSquare as IconDefinition,
  fasSquareCheck as IconDefinition,
  faListUl,
  faListOl,
  faListCheck,
  faSignature,
  faQuotes,
  faTableColumns
);

export default {
  install(app: any) {
    app.component("Icon", FontAwesomeIcon);
  },
};
