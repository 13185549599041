import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { UserState, SignInState, User, WorkspaceMeta } from "./types";
import { RootState } from "@/store/types";
import router from "@/router/index";
import gapi from "gapi-script";
import { app } from "@/main";

const state: UserState = {
  signInState: new Promise((resolve) => {
    //never resolve
    resolve(SignInState.None);
  }),
  users: [],
  active: -1,
};

const getters: GetterTree<UserState, RootState> = {
  // login state
  signInState(state): Promise<SignInState> {
    return state.signInState;
  },
  hasAccess:
    (state) =>
    (workspaceId: string): boolean => {
      return state.users
        .map((user) => user.workspaces.has(workspaceId))
        .reduce((a, b) => a || b, false);
    },
  getUsers(state): Array<User> {
    return state.users;
  },
  getUser:
    (state) =>
    (index: number): User => {
      return state.users[index];
    },
  getActiveUser(state): User {
    return state.users[state.active];
  },
  getUid(state): string {
    return state.users[state.active].uid;
  },
  getAuth(state): any {
    return state.users[state.active].authUser.getAuthResponse();
  },

  // workspaces
  getLastOpen(state): { workspace: string; page: string } {
    return state.users[state.active].lastOpen;
  },
  getWorkspaces(state): Set<string> {
    return state.users[state.active].workspaces;
  },
  getWorkspaceMeta:
    (state) =>
    (workspace: string): Promise<WorkspaceMeta> => {
      return new Promise((resolve, reject) => {
        fetch(`https://velocity.keryx.workers.dev/workspace?id=${workspace}`, {
          method: "GET",
          headers: {
            Authorization:
              "Bearer " +
              state.users[state.active].authUser.getAuthResponse().id_token,
          },
        }).then((response) => {
          if (response.status > 300) {
            reject(null);
          } else {
            response.json().then((result) => {
              resolve(new WorkspaceMeta(workspace, result.name, result.icon));
            });
          }
        });
      });
    },

  //settings
  getName(state) {
    return state.users[state.active].name;
  },
  getEmail(state) {
    return state.users[state.active].email;
  },
  getSetting:
    (state) =>
    (payload: string): any => {
      return state.users[state.active].settings[payload];
    },
};

const mutations: MutationTree<UserState> = {
  // login state
  updateSignInState(state, payload: Promise<SignInState>) {
    state.signInState = payload;
  },
  loadUser(state, payload: User) {
    state.users.push(payload);
    state.active = state.users.length - 1;

    return;
  },
  unload(state, index: number) {
    if (index < 0) {
      state.users = [];
      return;
    }
    delete state.users[0];
    return;
  },

  //workspaces
  addWorkspace(state, payload: string) {
    state.users[state.active].workspaces.add(payload);
  },
  removeWorkspace(state, payload: string) {
    state.users[state.active].workspaces.delete(payload);
  },
};

const actions: ActionTree<UserState, RootState> = {
  /* Loading ---------------------------------------------------------------- */
  loadUser({ commit }, payload: any): Promise<any> {
    const token = payload.getAuthResponse().id_token;
    return new Promise((resolve, reject) => {
      fetch("https://velocity.keryx.workers.dev/login/", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          if (response.status < 300) {
            response.json().then((res) => {
              const user = new User();
              user.load(
                res.uid,
                res.name,
                res.email,
                {
                  workspace: res["last-opened"].workspace,
                  page: res["last-opened"].page,
                },
                res.workspaces,
                payload
              );
              commit("loadUser", user);
              resolve({ ...user, newUser: res["new-user"] });
            });
          } else {
            response.text().then((res) => {
              console.warn(res);
              reject(res);
            });
          }
        })
        .catch((error) => {
          console.warn(error);
          reject(error);
        });
    });
  },
  unload({ commit }) {
    router.push("/login").then(() => {
      commit("unload");
      commit("workspace/unload", {}, { root: true });
      commit("pages/unload", {}, { root: true });
      commit("relationships/unload", {}, { root: true });
      commit("materials/unload", {}, { root: true });
    });
  },

  setLastOpen(
    { state },
    payload: { workspaceId: string; pageId: string }
  ): Promise<{ workspaceId: string; pageId: string }> {
    return new Promise((resolve) => {
      fetch("https://velocity.keryx.workers.dev/last-opened/", {
        method: "POST",
        headers: {
          Authorization:
            "Bearer " +
            state.users[state.active].authUser.getAuthResponse().id_token,
        },
        body: JSON.stringify({
          page: payload.pageId,
          workspace: payload.workspaceId,
        }),
      }).then(() => {
        resolve(payload);
      });
    });
  },

  /* DANGER */

  deleteWorkspace({ commit, dispatch, state, rootGetters }): Promise<string> {
    return new Promise((resolve, reject) => {
      const id = rootGetters["workspace/getId"];
      commit("removeWorkspace", id);
      fetch("https://velocity.keryx.workers.dev/workspace?id=" + id, {
        method: "DELETE",
        headers: {
          Authorization:
            "Bearer " +
            state.users[state.active].authUser.getAuthResponse().id_token,
        },
      });
    });
  },
  deleteAccount({ commit, state }): Promise<void> {
    return new Promise((resolve, reject) => {
      router.push({ name: "Login" });
      fetch("https://velocity.keryx.workers.dev/login/", {
        method: "DELETE",
        headers: {
          Authorization:
            "Bearer " +
            state.users[state.active].authUser.getAuthResponse().id_token,
        },
      }).then((response) => {
        if (response.status < 300) {
          app.config.globalProperties.$gapi
            .getAuthInstance()
            .then((result: any) => {
              result.disconnect();
              app.config.globalProperties.$gapi.logout().then(() => {
                resolve();
              });
            });
        } else {
          reject();
        }
      });
    });
  },
};

export const user: Module<UserState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
