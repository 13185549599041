import store from "@/store/index";
//import { PropertyType } from "@/store/relationships/types";
/*
import ListPage from "@/components/pages/views/ListView.vue";
import ListBlock from "@/components/pages/notes/ListBlock.vue";
import ListHelper from "@/components/pages/helpers/ListHelper.vue";

import InboxBlock from "@/components/pages/notes/InboxBlock.vue";
import InboxHelper from "@/components/pages/helpers/InboxHelper.vue";

import TimelinePage from "@/components/pages/views/TimelineView.vue";
import TimelineBlock from "@/components/pages/notes/TimelineBlock.vue";
import TimelineHelper from "@/components/pages/helpers/TimelineHelper.vue";

import BoardPage from "@/components/pages/views/BoardView.vue";
import BoardBlock from "@/components/pages/notes/BoardBlock.vue";
import BoardHelper from "@/components/pages/helpers/BoardHelper.vue";

import TablePage from "@/components/pages/views/TableView.vue";
import TableBlock from "@/components/pages/notes/TableBlock.vue";
import TableHelper from "@/components/pages/helpers/TableHelper.vue";
*/

/* Pages */
export interface PageState {
  [key: string]: Page;
}

export enum PageType {
  List,
  Inbox,
  Board,
  Waterfall,
  Timeline,
  Table,
}

export class Page {
  //state

  //metadata
  id: string;
  name: string;
  icon: string;
  cover: string;
  parent: string;
  //views: Array<PageView>;

  //contents
  children: Set<string>;
  //leads: { [key: string]: Lead };
  //materials: Set<string>;
  //properties: { [key: string]: PropertyRule };

  constructor(id: string, parent: string, children: Array<string>) {
    this.id = id;
    this.name = "";
    this.icon = "";
    this.cover = "";
    this.parent = parent;

    // this will be dependent on content probably
    this.children = new Set(children);
    //this.leads = leads;
    //this.materials = new Set(materials);
    //this.properties = {};
    //this.views = [];
  }

  /*
  encodeViews() {
    return JSON.stringify(this.views.map((view) => view.encodeContent()));
  }
  */

  getPath(): Array<Page> {
    if (this.parent == null) {
      return [];
    } else {
      return [this, ...store.getters["pages/getPage"](this.parent).getPath()];
    }
  }

  /*
  request() {
    this.requests += 1;
    if (this.requests == 1) {
      this.state = new Promise((resolve, reject) => {
        fetch(`https://velocity.keryx.workers.dev/page?id=${this.id}`, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + store.getters["user/getAuth"].id_token,
          },
        }).then((response) => {
          if (response.status < 300) {
            response.json().then((result) => {
              this.name = result.name;
              this.icon = result.icon;
              this.views = result.views.map((view: string) => {
                return PageView.load(view);
              });
              result["required-properties"].forEach((property: any) => {
                return (this.properties[property.id] = PropertyRule.load(
                  this.id,
                  property
                ));
              });
              resolve(true);
            });
          } else {
            response.text().then((result) => {
              console.warn(result);
              reject(true);
            });
          }
        });
      });
    }
  }

  free() {
    this.requests -= 1;
    if (this.requests == 0) {
      this.state = new Promise((resolve, reject) => {
        this.cover = "";

        // this will be dependent on content probably
        this.properties = {};
        this.views = [];
        resolve(false);
      });
    }
  }
  */

  static create(id: string, parent: string, name: string, icon: string): Page {
    const page = new Page(id, parent, []);
    page.name = name;
    page.icon = icon;
    //page.views = views;
    return page;
  }

  static getStarterPage(): Page {
    return this.create("", "", "Welcome to Velocity!", "🏘");
  }
  static getDefaultPage(): Page {
    return this.create("", "", "New Page", "");
  }
}

/*
export abstract class PageView {
  //readonly
  abstract readonly type: PageType;
  abstract readonly view: any;
  abstract readonly node: any;
  abstract readonly helperComponent: any;

  constructor() {
    // does this fix it?
  }

  abstract encodeContent(): string;

  static load(payload: any): PageView {
    let result: any;
    try {
      result = payload.length > 0 ? JSON.parse(payload) : {};
      if (result.type == PageType.Board) {
        const promotions: { [key: string]: Array<Date> } = {};
        if (result.content.promotions)
          Object.keys(result.content.promotions).forEach((lead) => {
            promotions[lead] = result.content.promotions[lead].map(
              (timestamp: string) => new Date(timestamp)
            );
          });
        return new BoardView(promotions);
      } else if (result.type == PageType.Timeline) {
        return new TimelineView(
          typeof result.content.timeframe != "undefined"
            ? (result.content.timeframe as Timeframe)
            : Timeframe.Week
        );
      } else if (result.type == PageType.Table) {
        return new TableView(result.columnWidths);
      } else if (result.type == PageType.List) {
        return new ListView();
      } else if (result.type == PageType.Inbox) {
        return new InboxView(result.content.search);
      } else {
        return new InboxView("");
      }
    } catch (error) {
      console.warn("error loading page view:", error);
      return new ListView();
    }
  }

  static createDefault(type: PageType) {
    if (type == PageType.Board) return new BoardView({});
    else if (type == PageType.Timeline) return new TimelineView(Timeframe.Week);
    else if (type == PageType.Table) return new TableView({});
    else if (type == PageType.Inbox) return new InboxView("");
    else return new ListView();
  }

  static typeIcon = {
    [PageType.Inbox]: ["fal", "inbox"],
    [PageType.List]: ["fal", "bars"],
    [PageType.Board]: ["fal", "table-layout"],
    [PageType.Waterfall]: ["fal", "chart-simple"],
    [PageType.Timeline]: ["fal", "timeline"],
    [PageType.Table]: ["fal", "table-list"],
  };
}
*/

/* Page Types */
/*
export class InboxView extends PageView {
  search: string;

  //readonlys
  type = PageType.Inbox;
  view = InboxView;
  node = InboxBlock;
  helperComponent = InboxHelper;

  constructor(search: string) {
    super();
    this.search = search;
  }

  encodeContent() {
    return JSON.stringify({
      type: PageType.Inbox,
      content: { search: this.search },
    });
  }
}

export class ListView extends PageView {
  //readonlys
  type = PageType.List;
  view = ListPage;
  node = ListBlock;
  helperComponent = ListHelper;

  constructor() {
    super();
  }

  encodeContent() {
    return JSON.stringify({ type: PageType.List });
  }
}

export enum Source {
  Twitter,
  Email,
}

export class TimelineView extends PageView {
  timeframe: Timeframe;

  //readonlys
  type = PageType.Timeline;
  view = TimelinePage;
  node = TimelineBlock;
  helperComponent = TimelineHelper;

  constructor(timeframe: Timeframe) {
    super();
    this.timeframe = timeframe;
  }

  encodeContent() {
    return JSON.stringify({
      type: PageType.Timeline,
      content: { timeframe: this.timeframe },
    });
  }
}

export enum Timeframe {
  Month,
  Week,
  Day,
}

export class BoardView extends PageView {
  promotions: { [key: string]: Array<Date> };

  //readonlys
  type = PageType.Board;
  view = BoardPage;
  node = BoardBlock;
  helperComponent = BoardHelper;

  constructor(promotions: { [key: string]: Array<Date> }) {
    super();
    this.promotions = promotions;
  }

  encodeContent() {
    return JSON.stringify({
      type: PageType.Board,
      content: {
        promotions: this.promotions,
      },
    });
  }
}

export class TableView extends PageView {
  columnWidths: { [key: string]: number };

  type = PageType.Table;
  view = TablePage;
  node = TableBlock;
  helperComponent = TableHelper;

  constructor(columnWidths: { [key: string]: number }) {
    super();
    this.columnWidths = columnWidths;
  }

  encodeContent() {
    return JSON.stringify({
      type: PageType.Table,
      conent: {
        columnWidths: this.columnWidths,
      },
    });
  }
}
*/

export interface LoadingState {
  [key: string]: Promise<Page>;
}

/* Leads */

export interface Contact {
  method: Method;
  address: string;
}
/*
export class Lead {
  //state
  requests: number;
  state: Promise<boolean>;

  // metadata
  id: string;
  page: string;
  name: string;
  relationships: Array<string>;

  constructor(id: string, page: string, relationships: Array<string>) {
    this.requests = 0;
    this.state = new Promise((resolve) => {
      resolve(false);
    });
    this.id = id;
    this.page = page;
    this.relationships = relationships;

    this.name = "";
  }

  request() {
    this.requests += 1;
    if (this.requests == 1) {
      this.state = new Promise((resolve, reject) => {
        fetch(`https://velocity.keryx.workers.dev/lead?id=${this.id}`, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + store.getters["user/getAuth"].id_token,
          },
        }).then((response) => {
          if (response.status < 300) {
            response.json().then((result) => {
              this.name = result.name;
              resolve(true);
            });
          } else {
            response.text().then((result) => {
              console.warn(result);
              reject(true);
            });
          }
        });
      });
    }
  }

  free() {
    this.requests -= 1;
    if (this.requests == 0) {
      this.state = new Promise((resolve, reject) => {
        this.name = "";
        resolve(false);
      });
    }
  }
}
*/

export enum Method {
  Email,
  Twitter,
}

export type PalletColor =
  | "rose"
  | "orange"
  | "gold"
  | "green"
  | "emerald"
  | "sky"
  | "indigo"
  | "violet";
export const PalletColors = [
  "rose",
  "orange",
  "gold",
  "green",
  "emerald",
  "sky",
  "indigo",
  "violet",
] as Array<PalletColor>;

/* Property Rules */
/*
export class PropertyRule {
  id: string;
  type: PropertyType;
  key: string;
  color: PalletColor;
  page: string;

  constructor(
    id: string,
    page: string,
    type: PropertyType,
    key: string,
    color?: PalletColor
  ) {
    this.id = id;
    this.page = page;
    this.type = type;
    this.key = key;
    this.color = color
      ? color
      : PalletColors[Math.floor(PalletColors.length * Math.random())];
  }

  static load(page: string, payload: any): PropertyRule {
    const rule = new PropertyRule(
      payload.id,
      page,
      payload.type,
      payload.key,
      payload.color
    );
    return rule;
  }
}
*/
/* Configs */

export class Search {}

export class Sort {}

export class Filter {}
