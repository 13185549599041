import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/store/types";
import {
  UtilState,
  Location,
  ContextMenu,
  EditorCommandContextMenu,
  //RelationshipSelectionContextMenu,
  Helper,
} from "./types";
import { SuggestionItem } from "@/components/editor/types";

const state: UtilState = {
  contextmenu: null,
  helper: null,

  savingJobs: 0,
  savingWorker: new Worker(window.location.origin + "/savingWorker.js"),
};

const getters: GetterTree<UtilState, RootState> = {
  /* Menus */
  getMenu(state): null | ContextMenu {
    return state.contextmenu;
  },

  /* Helper */
  getHelper(state): null | Helper {
    return state.helper;
  },

  getSavingJobs(state): number {
    return state.savingJobs;
  },
  getSavingWorker(state): Worker {
    return state.savingWorker;
  },

  /* Image */
  getFile:
    (state, getters, rootState, rootGetters) =>
    (id: string): Promise<{ name: string; type: string; file: string }> => {
      return new Promise((resolve, reject) => {
        fetch(`https://velocity.keryx.workers.dev/file?id=${id}`, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + rootGetters["user/getAuth"].id_token,
          },
        }).then((response) => {
          if (response.status == 200) {
            response.json().then((result) => {
              resolve(result);
            });
          } else {
            response.text().then((result) => {
              console.warn(`error getting image ${id}`, result);
              reject();
            });
          }
        });
      });
    },
};

const mutations: MutationTree<UtilState> = {
  /* Menus */
  pushMenu(state, payload: ContextMenu) {
    state.contextmenu = payload;
  },
  closeMenu(state) {
    state.contextmenu = null;
  },

  // editor menu methods
  updateMenuItems(state, payload: { query: string; command: any }) {
    (state.contextmenu as EditorCommandContextMenu).query = payload.query;
    (state.contextmenu as EditorCommandContextMenu).command = payload.command;
  },
  updateMenuLocation(state, payload: Location) {
    (state.contextmenu as ContextMenu).location = payload;
  },
  updateMenuSearch(state, payload: string) {
    (state.contextmenu as any).search = payload;
  },

  /* Helers */
  pushHelper(state, payload: Helper) {
    state.helper = payload;
  },
  closeHelper(state) {
    state.helper = null;
  },

  /* Workers */
  setupWorkers(state) {
    state.savingWorker.onmessage = (e) => {
      state.savingJobs = state.savingJobs - 1;
    };
  },
  addSavingJob(state) {
    state.savingJobs = state.savingJobs + 1;
  },
};

const actions: ActionTree<UtilState, RootState> = {
  /* Menus */
  pushMenu({ commit }, payload: ContextMenu) {
    commit("pushMenu", payload);
  },
  closeMenu({ commit }) {
    commit("closeMenu");
  },
  menuKeyHandler({ state }, payload: KeyboardEvent) {
    if (state.contextmenu != null) {
      state.contextmenu.keyHandler(payload);
    }
  },

  // editor menu methods
  updateMenuItems({ commit }, payload: { query: string; command: any }) {
    commit("updateMenuItems", payload);
  },
  updateMenuLocation({ commit }, payload: Location) {
    commit("updateMenuLocation", payload);
  },
  updateMenuSearch({ commit }, payload: string) {
    commit("updateMenuSearch", payload);
  },

  /* Helpers */
  pushHelper({ commit }, payload: Helper) {
    commit("pushHelper", payload);
  },
  closeHelper({ commit }) {
    commit("closeHelper");
  },

  /* Workers */
  postToSaving(
    { commit, state },
    payload: { url: string; auth: any; payload: any }
  ) {
    commit("addSavingJob");
    state.savingWorker.postMessage(payload);
  },

  /* Image */
  storeFile(
    { rootGetters },
    payload: { name: string; type: string; file: string }
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      fetch(
        `https://velocity.keryx.workers.dev/file?workspace_id=${rootGetters["workspace/getId"]}`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + rootGetters["user/getAuth"].id_token,
          },
          body: JSON.stringify({
            name: payload.name,
            type: payload.type,
            file: payload.file,
          }),
        }
      ).then((response) => {
        if (response.status == 200) {
          response.json().then((result) => {
            resolve(result);
          });
        } else {
          response.text().then((result) => {
            console.warn(`error storing image ${payload.name}`, result);
            reject();
          });
        }
      });
    });
  },
  deleteFile({ rootGetters }, payload: string): Promise<string> {
    return new Promise((resolve, reject) => {
      fetch(`https://velocity.keryx.workers.dev/file?id=${payload}`, {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + rootGetters["user/getAuth"].id_token,
        },
      }).then((response) => {
        if (response.status == 200) {
          response.json().then((result) => {
            resolve(result);
          });
        } else {
          response.text().then((result) => {
            console.warn(`error deleting image ${payload}`, result);
            reject();
          });
        }
      });
    });
  },
};

export const util: Module<UtilState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
