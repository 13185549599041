
import { defineComponent } from "vue";
import { app } from "@/main";
import store from "@/store/index";
import router from "@/router/index";
import { ContextMenu } from "@/store/util/types";
export default defineComponent({
  name: "App",
  data() {
    return {
      location: { "z-index": "10", left: "0", top: "0" } as {
        [key: string]: string;
      },
    };
  },
  watch: {
    _menu: function (menu: ContextMenu) {
      if (menu == null) {
        return { "z-index": "10", left: "0", top: "0" };
      } else {
        const style: { [key: string]: string } = { "z-index": "10" };
        const height = menu.location.height ? menu.location.height : 0;
        if (menu.location.x + 240 + 50 < window.innerWidth)
          style["left"] = menu.location.x + "px";
        else style["right"] = window.innerWidth - menu.location.x + "px";
        if (menu.location.y + 200 + 50 < window.innerHeight)
          style["top"] = menu.location.y + height + "px";
        else style["bottom"] = window.innerHeight - menu.location.y + "px";
        this.location = style;
      }
    },
  },
  computed: {
    _menu: function (): ContextMenu {
      return store.getters["util/getMenu"];
    },
    _location: function (menu: ContextMenu) {
      const style: { [key: string]: string } = { "z-index": "10" };
      if (this._menu == null) {
        return {};
      }
      if (this._menu.location.x + 240 + 50 < window.innerWidth)
        style["left"] = this._menu.location.x + "px";
      else style["right"] = "calc(100vw - " + this._menu.location.x + "px)";
      if (this._menu.location.y + 200 + 50 < window.innerHeight)
        style["top"] = this._menu.location.y + "px";
      else
        style["bottom"] =
          "calc(100vh - " + (this._menu.location.y + 27) + "px)";
      return style;
    },
  },
});
