import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouteRecord,
} from "vue-router";
import store from "@/store/index";
import { app } from "@/main";

/*
is logged in
  yes -> go
  no -> is there a saved auth state
    yes -> login -> go
    no -> go to login page
*/

const getLogin = function (): Promise<boolean> {
  return store.getters["user/loggingIn"];
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Root",
    component: () => import("@/views/Root.vue"),
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    meta: {
      requireLogin: false,
    },
  },
  {
    path: "/welcome",
    name: "Welcome",
    component: () => import("@/views/Welcome.vue"),
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/-",
    name: "Foyer",
    component: () => import("@/views/Foyer.vue"),
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/join/:key",
    name: "Join",
    component: () => import("@/views/Join.vue"),
    meta: {
      requireLogin: false,
    },
  },
  {
    path: "/new",
    name: "New",
    component: () => import("@/views/New.vue"),
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/-/:workspaceId",
    name: "Workspace",
    component: () => import("@/views/Workspace.vue"),
    meta: {
      requireLogin: true,
    },
    children: [
      {
        path: ":pageId",
        name: "Page",
        component: () => import("@/views/workspace/Page.vue"),
        meta: {
          requireLogin: true,
        },
      },
      {
        path: "",
        name: "Workspace Root",
        component: () => import("@/views/workspace/WorkspaceRoot.vue"),
        meta: {
          requireLogin: true,
        },
      },
    ],
  },
  {
    path: "/connect",
    name: "Connect",
    component: () => import("@/views/Connect.vue"),
    meta: {
      requireLogin: true,
    },
    children: [
      {
        path: "stripe",
        name: "Stripe",
        component: () => import("@/views/connect/Stripe.vue"),
        meta: {
          requireLogin: true,
        },
      },
      {
        path: "join/:collabKey",
        name: "Collab",
        component: () => import("@/views/connect/Collab.vue"),
        meta: {
          requireLogin: true,
        },
      },
      {
        path: "twitter",
        name: "Twitter Redirect",
        component: () => import("@/views/connect/CaptureTwitter.vue"),
        meta: {
          requireLogin: true,
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import("@/views/NotFound.vue"),
    meta: {
      requireLogin: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
