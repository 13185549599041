import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store/index";
import { gapiConfig } from "@/config/gapiConfig";
import VueGapi from "vue-gapi";
import { User, SignInState } from "@/store/workspace/types";
import icons from "./icons";

import axios from "axios";

import "./core.css";
import "./styles.css";
import "./prosemirror.css";
import "./page.css";

const logBuildLifecycle = false;

const start = new Date();
export const app = createApp(App);
logBuildLifecycle &&
  console.log("Built app:", new Date().getTime() - start.getTime(), "ms");
app.use(icons);
logBuildLifecycle &&
  console.log("Built graphics:", new Date().getTime() - start.getTime(), "ms");
app.use(store);
logBuildLifecycle &&
  console.log("Built store:", new Date().getTime() - start.getTime(), "ms");
app.use(router);
logBuildLifecycle &&
  console.log("Built router:", new Date().getTime() - start.getTime(), "ms");
app.use(VueGapi, {
  apiKey: gapiConfig.api,
  clientId: gapiConfig.client,
  // discoveryDocs: firebaseConfig.discoveryDocs,
  scope: gapiConfig.scopes.join(" "),
});
logBuildLifecycle &&
  console.log("Built gmail api:", new Date().getTime() - start.getTime(), "ms");
app.config.unwrapInjectedRef = true;
logBuildLifecycle &&
  console.log("Built app in:", new Date().getTime() - start.getTime(), "ms");

export const signInFlow = (route?: string) => {
  return new Promise<SignInState>((resolve) => {
    /* Get Authentication from Gapi */
    app.config.globalProperties.$gapi.getCurrentUser().then((account: any) => {
      logBuildLifecycle &&
        console.log(
          "Authenticated:",
          new Date().getTime() - start.getTime(),
          "ms"
        );
      store
        .dispatch("user/loadUser", account)
        .then((user: any) => {
          logBuildLifecycle &&
            console.log(
              "Loaded User:",
              new Date().getTime() - start.getTime(),
              "ms"
            );
          // If routing is needed
          if (user.newUser) {
            router.push("/welcome");
          } else if (router.currentRoute.value.name == "Welcome") {
            window.open("/login", "_self");
          } else if (
            router.currentRoute.value.name == "Login" ||
            router.currentRoute.value.name == "Root"
          ) {
            logBuildLifecycle && console.log("routing");
            if (user.lastOpen.workspace.length > 0) {
              if (user.lastOpen.page.length > 0) {
                router
                  .push({
                    name: "Page",
                    params: {
                      workspaceId: user.lastOpen.workspace,
                      pageId: user.lastOpen.page,
                    },
                  })
                  .then(() => {
                    resolve(SignInState.Success);
                  });
              } else {
                router
                  .push({
                    name: "Workspace",
                    params: {
                      workspaceId: user.lastOpen.workspace,
                    },
                  })
                  .then(() => {
                    resolve(SignInState.Success);
                  });
              }
            } else {
              if (user.workspaces.size > 0) {
                router
                  .push({
                    name: "Workspace",
                    params: {
                      workspaceId: [...user.workspaces][0],
                    },
                  })
                  .then(() => {
                    resolve(SignInState.Success);
                  });
              } else {
                console.log("create new workspace");
              }
            }
          }
          resolve(SignInState.Success);
        })
        .catch((error) => {
          console.warn("login error");
          resolve(SignInState.Failure);
        });
    });
  });
};
(async () => {
  logBuildLifecycle && console.log("Checking sign in state...");
  /* Check signin state */
  logBuildLifecycle && console.log(app.config.globalProperties.$gapi);
  const signInState = await app.config.globalProperties.$gapi.isSignedIn();
  logBuildLifecycle &&
    console.log(
      "Sign in state:",
      signInState,
      "in:",
      new Date().getTime() - start.getTime(),
      "ms"
    );

  /* Wait for router */
  router.isReady().then(() => {
    logBuildLifecycle &&
      console.log(
        "Router is ready:",
        new Date().getTime() - start.getTime(),
        "ms"
      );
    if (signInState == true) {
      logBuildLifecycle &&
        console.log(
          "Signing in...",
          new Date().getTime() - start.getTime(),
          "ms"
        );
      store.commit("user/updateSignInState", signInFlow());
    } else {
      if (router.currentRoute.value.meta.requireLogin) {
        router.push("/login");
      }
    }
    app.mount("#app");
    logBuildLifecycle &&
      console.log("Mounted app:", new Date().getTime() - start.getTime());
  });

  /* Workers */
  store.commit("util/setupWorkers");
  window.onbeforeunload = function () {
    if (store.getters["util/getSavingJobs"] > 0)
      return "not all changes are saved";
  };
})();

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    store: any; // replace it with the right type
  }
}
