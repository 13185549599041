import { resolveDynamicComponent as _resolveDynamicComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "velocity" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx._menu != null)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx._menu.component), {
          key: 0,
          style: _normalizeStyle(_ctx.location),
          class: "absolute context-menu",
          menu: _ctx._menu,
          ref: "menu"
        }, null, 8, ["style", "menu"]))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view)
  ]))
}