import Vuex, { StoreOptions } from "vuex";
import crypto from "crypto-js";
import { RootState } from "@/store/types.ts";

import { user } from "@/store/workspace/user";
import { util } from "@/store/util/util";
import { workspace } from "@/store/workspace/workspace";
import { pages } from "@/store/pages/pages";
//import { relationships } from "@/store/relationships/relationships";
//import { materials } from "@/store/materials/materials"
//import { messages } from "@/store/messages/messages"

export const genId = function (seed: string, prefix: string): string {
  return (
    crypto.MD5(seed).toString() +
    "-" +
    prefix +
    "-" +
    crypto.lib.WordArray.random(20)
  );
};

const store: StoreOptions<RootState> = {
  modules: {
    user,
    util,
    workspace,
    pages,
    //relationships,
    //materials,
    //messages,
  },
};

export default new Vuex.Store<RootState>(store);
