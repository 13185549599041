import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { TeamState, Team, Member, Role, MemberRequest } from "./types";
import { RootState } from "@/store/types";

const state: TeamState = {
  members: {},
  requests: {},
};

const getters: GetterTree<TeamState, RootState> = {
  getMembers(state, getters, rootState, rootGetters): Promise<Array<string>> {
    console.log(rootGetters);
    return new Promise((resolve, reject) => {
      fetch(
        `https://velocity.keryx.workers.dev/workspace?id=${rootGetters["workspace/getId"]}`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + rootGetters["user/getAuth"].id_token,
          },
        }
      ).then((response) => {
        if (response.status < 300) {
          response.json().then((result) => {
            console.log(result);
            resolve(result.users);
          });
        } else {
          response.text().then((result) => {
            console.warn(result);
            reject([]);
          });
        }
      });
    });
  },
  getMemberMeta:
    (state, getters, rootState, rootGetters) =>
    (user: string): Promise<Member> => {
      return new Promise<Member>((resolve, reject) => {
        fetch(`https://velocity.keryx.workers.dev/user?user_id=${user}`, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + rootGetters["user/getAuth"].id_token,
          },
        }).then((userResponse) => {
          if (userResponse.status < 300) {
            userResponse.json().then((userResult) => {
              resolve(
                new Member(user, userResult.name, userResult.email, Role.Member)
              );
            });
          } else {
            userResponse.text().then((userResult) => {
              console.warn(userResult);
              resolve(
                new Member(
                  user,
                  "",
                  "( couldn't load user information )",
                  Role.Error
                )
              );
            });
          }
        });
      });
    },

  getTeamInvites(
    state,
    getters,
    rootState,
    rootGetters
  ): Promise<Array<Member>> {
    return new Promise((resolve, reject) => {
      fetch(
        `https://velocity.keryx.workers.dev/open-collab?workspace_id=${rootGetters["workspace/getId"]}`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + rootGetters["user/getAuth"].id_token,
          },
        }
      ).then((response) => {
        console.log(response);
        if (response.status < 300) {
          response.json().then((result) => {
            console.log(result);
            resolve(
              result.map(
                (res: any) => new Member(res.token, "", res.email, Role.Pending)
              )
            );
          });
        } else {
          response.text().then((result) => {
            console.warn(result);
            reject(result);
          });
        }
      });
    });
  },

  /* ###################################################################### */
  /*
  getTeam(state): Array<Member> {
    return [
      new Member("0", "Timmy", "timothyhein@kryx.co", Role.Owner),
      new Member("1", "Connor", "connorklaassen@kryx.co", Role.Owner),
      new Member("2", "Erika", "ermerideth7@gmail.com", Role.Owner),
    ];
  },
  getMembers(state): Array<Member> {
    return Object.values(state.members);
  },
  getMember:
    (state) =>
    (payload: string): Member => {
      return state.members[payload];
    },
  getRequests(state): Array<MemberRequest> {
    return Object.values(state.requests);
  },
  getRequest:
    (state) =>
    (payload: string): MemberRequest => {
      return state.requests[payload];
    },
    */
};

const mutations: MutationTree<TeamState> = {
  /* Members */
  addMember(state, payload: Member) {
    state.members[payload.id] = payload;
  },
  removeMember(state, payload: string) {
    delete state.members[payload];
  },

  /* Requests */
  addRequest(state, payload: MemberRequest) {
    state.requests[payload.email] = payload;
  },
  removeRequest(state, payload: string) {
    delete state.requests[payload];
  },
};

const actions: ActionTree<TeamState, RootState> = {
  sendInvite({ rootGetters }, payload: string): Promise<Member> {
    return new Promise((resolve, reject) => {
      fetch(
        `https://velocity.keryx.workers.dev/collab?workspace_id=${rootGetters["workspace/getId"]}`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + rootGetters["user/getAuth"].id_token,
          },
          body: JSON.stringify({ email: payload }),
        }
      ).then((response) => {
        if (response.status < 300) {
          response.json().then((result) => {
            console.log(result);
            resolve(new Member(result.token, "", payload, Role.Pending));
          });
        } else {
          response.text().then((result) => {
            console.warn(result);
            reject(new Member("", "", "", Role.Error));
          });
        }
      });
    });
  },
  revokeInvite({ rootGetters }, payload: string): Promise<void> {
    return new Promise((resolve, reject) => {
      fetch(`https://velocity.keryx.workers.dev/open-collab?key=${payload}`, {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + rootGetters["user/getAuth"].id_token,
        },
      }).then((response) => {
        if (response.status < 300) {
          resolve();
        } else {
          response.text().then((result) => {
            console.warn(result);
            reject();
          });
        }
      });
    });
  },
};

export const team: Module<TeamState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
