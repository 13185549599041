import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { WorkspaceState, Package } from "./types";
import { Page } from "@/store/pages/types";
import { RootState } from "@/store/types";

import { team } from "./team";

const state: WorkspaceState = {
  isLoaded: false,
  loading: false,
  id: "",
  name: "",
  icon: "",
  pages: new Set([]),
  type: Package.Free,

  // Collaboration
  websocket: null as null | WebSocket,
  beforeConnect: {},
  onConnect: {},
  onMessage: {},
  afterClose: {},

  // Settings
  layout: {
    hideNavbar: false,
    fountain: false,
  },
};

const getters: GetterTree<WorkspaceState, RootState> = {
  //Collaboration
  getWebsocket(state): WebSocket {
    return state.websocket as WebSocket;
  },
  getConnectionStatus(state): number {
    if (state.websocket == null) return 0;
    else return state.websocket.readyState;
  },
  // metadata
  getId(state): string {
    return state.id;
  },
  getName(state): string {
    return state.name;
  },
  getIcon(state): string {
    return state.icon;
  },
  getPlan(state): Package {
    return state.type;
  },

  //loading state
  loaded(state): boolean {
    // worksapce data is accessible
    return state.isLoaded;
  },
  loading(state): boolean {
    return state.loading;
  },

  // page settings
  getPages(state): Set<string> {
    return state.pages;
  },
  getDefaultPage(state): string {
    if (state.pages.size == 0) return "empty";
    const [first] = state.pages;
    return first;
  },

  // layout settings
  hideNavbar(state): boolean {
    return state.layout.hideNavbar;
  },
  fountain(state): boolean {
    return state.layout.fountain;
  },
};

const mutations: MutationTree<WorkspaceState> = {
  /* State Management */
  addPage(state, payload: string) {
    state.pages.add(payload);
  },
  removePage(state, payload: string) {
    state.pages.delete(payload);
  },

  /* Metadata */
  setName(state, payload: string) {
    console.log("setting name", state, payload);
    state.name = payload;
  },
  setIcon(state, payload: string) {
    state.icon = payload;
  },

  /* Loading */
  loading(state, payload: boolean) {
    state.loading = payload;
  },
  loadWorkspace(
    state,
    payload: {
      id: string;
      name: string;
      icon: string;
      pages: Array<string>;
      type: Package;
    }
  ) {
    state.isLoaded = true;
    state.id = payload.id;
    state.icon = payload.icon;
    state.name = payload.name;
    state.pages = new Set(payload.pages);
    state.type = payload.type;
  },
  unload(state) {
    state.isLoaded = false;
    state.loading = false;
    state.id = "";
    state.name = "";
    state.icon = "";
    state.pages = new Set([]);
    state.type = Package.Free;
  },

  /* Layout */
  toggleNavbar(state, payload) {
    state.layout.hideNavbar = payload;
  },
  toggleFountain(state, payload) {
    state.layout.fountain = payload;
  },
};

const actions: ActionTree<WorkspaceState, RootState> = {
  /* State Management */
  addPage({ commit }, payload: string): Promise<string> {
    return new Promise((resolve) => {
      console.log("adding page:", payload);
      commit("addPage", payload);
      resolve(payload);
    });
  },
  removePage({ commit }, payload: string): Promise<string> {
    return new Promise((resolve) => {
      console.log("removing page", payload);
      commit("removePage", payload);
      resolve(payload);
    });
  },

  handleState({ dispatch }, message: any) {
    if (message.data === "status" || message.data === "outside") {
      console.warn("¯_(ツ)_/¯");
    } else {
      const res = JSON.parse(message.data);
      if (res.type == "state") {
        console.log(res);
        dispatch(res.method.replace(/\//, "/_"), res.payload, {
          root: true,
        });
      }
    }
  },

  /* Metadata */
  setName({ commit }, payload: string) {
    commit("setName", payload);
  },
  setIcon({ commit }, payload: string) {
    commit("setIcon", payload);
  },

  /* Loading */
  create({ commit, rootGetters }): Promise<string> {
    return new Promise((resolve, reject) => {
      fetch("https://velocity.keryx.workers.dev/workspace/", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + rootGetters["user/getAuth"].id_token,
        },
        body: JSON.stringify({
          name: "New Workspace",
        }),
      }).then((response) => {
        if (response.status < 300) {
          response.json().then((res) => {
            commit("user/addWorkspace", res._key, { root: true });
            resolve(res._key);
          });
        } else {
          response.text().then((result) => {
            console.error(result);
            reject(result);
          });
        }
      });
    });
  },
  loadWorkspace(
    { commit, dispatch, state, rootGetters },
    payload: string
  ): Promise<WorkspaceState> {
    commit("loading", true);
    return new Promise((resolve, reject) => {
      fetch("https://velocity.keryx.workers.dev/workspace?id=" + payload, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + rootGetters["user/getAuth"].id_token,
        },
      }).then((response) => {
        if (response.status < 300) {
          response.json().then((res) => {
            // Make sure we're starting from a fresh workspace
            dispatch("unload");
            commit("loadWorkspace", {
              id: payload,
              name: res.name,
              icon: res.icon,
              pages: [],
              type: res.type,
            });
            commit("loading", false);
            resolve(state);
            // Init Pages
            res["top-level-pages"].forEach((page: string) => {
              dispatch("pages/init", page, { root: true }).then((page) => {
                commit("addPage", page.id);
              });
            });
            // Init Relationships
            res.relationships.forEach((relationship: string) => {
              dispatch("relationships/load", relationship, {
                root: true,
              });
            });
          });
        } else {
          response.text().then((result) => {
            console.error(result);
            reject(result);
          });
        }
      });
    });
  },
  unload({ commit }) {
    commit("unload");
    commit("pages/unload", {}, { root: true });
  },

  /* Saving */
  save({ dispatch, state, rootGetters }, payload: Array<"name" | "icon">) {
    dispatch(
      "util/postToSaving",
      JSON.stringify({
        url: "workspace/save",
        auth: rootGetters["user/getAuth"],
        payload: {
          workspace: state.id,
          ...(payload.includes("name") && { name: state.name }),
          ...(payload.includes("icon") && { icon: state.icon }),
        },
      }),
      { root: true }
    );
  },

  /* Clearing */
  clearRelationship({ dispatch, state }, payload: string): Promise<any> {
    return Promise.all(
      [...state.pages].map((page) => {
        return dispatch(
          "pages/clearRelationship",
          { page: page, relationship: payload },
          { root: true }
        );
      })
    );
  },

  /* Layout */
  toggleNavbar({ commit }, payload) {
    commit("toggleNavbar", payload);
  },
  toggleFountain({ commit }, payload) {
    commit("toggleFountain", payload);
  },
};

const modules = {
  team,
};

export const workspace: Module<WorkspaceState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules,
};
