import crypto from "crypto-js";
import store from "@/store/index";
import { PalletColors } from "@/store/pages/types";

export interface UserState {
  signInState: Promise<SignInState>;
  users: Array<User>;
  active: number;
}

export enum SignInState {
  None,
  Success,
  Failure,
}

export class User {
  // metadata
  uid: string;
  name: string;
  email: string;
  workspaces: Set<string>;
  authUser: any;

  //workspace data
  lastOpen: { workspace: string; page: string };
  settings: { [key: string]: any };

  constructor() {
    this.uid = "";
    this.name = "";
    this.email = "";
    this.workspaces = new Set([]);
    this.lastOpen = { workspace: "", page: "" };
    this.settings = {
      "navbar-width": 280,
      "display-color": "",
    };
  }

  load(
    uid: string,
    name: string,
    email: string,
    lastOpen: { workspace: string; page: string },
    workspaces: Array<string>,
    authUser: any,
    settings?: { [key: string]: any }
  ) {
    this.uid = uid;
    this.name = name;
    this.email = email;
    this.lastOpen = lastOpen;
    this.workspaces = new Set(workspaces);
    this.authUser = authUser;
    //navbar width
    if (settings && settings["navbar-width"]) {
      this.settings["navbar-width"] = settings["navbar-width"];
    } else {
      this.settings["navbar-width"] = 280;
    }

    if (settings && settings["display-color"]) {
      this.settings["display-color"] = settings["display-color"];
    } else {
      this.settings["display-color"] =
        PalletColors[Math.floor(PalletColors.length * Math.random())];
    }
  }
}

/* Team */

export interface TeamState {
  members: { [key: string]: Member };
  requests: { [key: string]: MemberRequest };
}

export class Team {
  members: Array<Member>;

  constructor(members: Array<Member>) {
    this.members = members;
  }
}

export interface MemberRequest {
  collabKey: string;
  email: string;
}

export class Member {
  id: string;
  name: string;
  email: string;
  role: Role;

  constructor(id: string, name: string, email: string, role: Role) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.role = role;
  }
}

export enum Role {
  Owner,
  Member,
  Error,
  Pending,
  Revoked,
}

/* Workspace */

export interface WorkspaceState {
  // State
  pages: Set<string>;

  //Metadata
  id: string;
  name: string;
  icon: string;
  type: Package;

  //Loading
  isLoaded: boolean;
  loading: boolean;

  //collaboration
  websocket: null | WebSocket;
  beforeConnect: { [key: string]: () => void };
  onConnect: { [key: string]: () => void };
  onMessage: { [key: string]: (message: any) => void };
  afterClose: { [key: string]: () => void };

  //Layout & Settings
  layout: { [key: string]: any };
}

export class WorkspaceMeta {
  id: string;
  name: string;
  icon: string;

  constructor(id: string, name: string, icon: string) {
    this.id = id;
    this.name = name;
    this.icon = icon;
  }
}

export enum Package {
  Free = "free",
  Team = "team",
}
