import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import {
  PageState,
  Page,
  //TimelineView,
  //BoardView,
  //PageType,
  //Lead,
  //PageView,
  //Timeframe,
  //PropertyRule,
  //PalletColor,
} from "./types";
//import { PropertyType } from "@/store/relationships/types";
import { genId } from "@/store/index";
import { RootState } from "@/store/types";
//import { loading } from "./loading";

const state: PageState = {};

const getters: GetterTree<PageState, RootState> = {
  /* Pages */
  getPage:
    (state) =>
    (page: string): Page => {
      return state[page];
    },
  getPath:
    (state) =>
    (page: string): Array<string> => {
      if (typeof state[page] == "undefined") return [];
      const out: Array<string> = [];
      const pushOut = (path: string) => {
        if (path == "") {
          return;
        } else if (typeof state[path] == "undefined") {
          out.push(path);
          return;
        } else {
          pushOut(state[path].parent);
          out.push(path);
        }
      };
      pushOut(page);
      return out;
    },

  getLocalChildren:
    (state) =>
    (page: string): Set<string> => {
      return state[page].children;
    },
  getChildren:
    (state, getters) =>
    (page: string): Array<string> => {
      const getChildren = (page: string): Array<string> => {
        return [
          ...[...state[page].children],
          ...[...state[page].children]
            .map((child: string) => getChildren(child))
            .flat(),
        ];
      };
      return [...getChildren(page)];
    },

  /* Metadata */
  getName:
    (state) =>
    (page: string): string => {
      return state[page].name;
    },
  getIcon:
    (state) =>
    (page: string): string => {
      return state[page].icon;
    },
  getCover:
    (state) =>
    (page: string): string => {
      return state[page].cover;
    },

  /* Metaobjects */
  /*
  getLeads:
    (state, getters) =>
    (page: string): Array<Lead> => {
      if (state[page]) {
        return [
          Object.values(state[page].leads),
          ...[...state[page].children].map(
            (child: string): Array<Lead> =>
              Object.values(getters["getLeads"](child))
          ),
        ].flat();
      } else {
        return [];
      }
    },
  getLead:
    (state, getters) =>
    (page: string, lead: string): Lead => {
      const out = state[page].leads[lead];
      if (out) {
        return out;
      } else {
        return getters["getLeads"](page).find((a: Lead) => a.id == lead);
      }
    },
  getLeadName:
    (state) =>
    (page: string, lead: string): string => {
      return state[page].leads[lead].name;
    },
  getLocalMaterials:
    (state) =>
    (page: string): Set<string> => {
      return state[page].materials;
    },
  getMaterials:
    (state, getters) =>
    (page: string): Array<string> => {
      if (state[page]) {
        return [
          ...state[page].materials,
          ...getters["getMaterials"](state[page].parent),
        ].flat();
      } else {
        return [];
      }
    },
  getProperty:
    (state) =>
    (page: string, property: string): PropertyRule => {
      return state[page].properties[property];
    },
  getLocalProperties:
    (state) =>
    (page: string): Array<PropertyRule> => {
      return Object.values(state[page].properties);
    },
  getProperties:
    (state, getters) =>
    (page: string): Array<PropertyRule> => {
      if (state[page]) {
        return [
          Object.values(state[page].properties),
          ...getters["getProperties"](state[page].parent),
        ].flat();
      } else {
        return [];
      }
    },

  getViews:
    (state) =>
    (page: string): Array<PageView> => {
      return state[page].views;
    },
  getView:
    (state) =>
    (page: string, view: number): PageView => {
      return state[page].views[view];
    },
    */
};

const mutations: MutationTree<PageState> = {
  /* State */
  addPage(state, payload: { parent: string; child: string }) {
    state[payload.parent].children.add(payload.child);
  },
  removePage(state, payload: { parent: string; child: string }) {
    state[payload.parent].children.delete(payload.child);
  },
  //Materials
  /*
  addMaterial(state, payload: { page: string; material: string }) {
    state[payload.page].materials.add(payload.material);
  },
  removeMaterial(state, payload: { page: string; material: string }) {
    state[payload.page].materials.delete(payload.material);
  },
  */
  //Leads
  /*
  addLead(state, payload: Lead) {
    state[payload.page].leads[payload.id] = payload;
  },
  removeLead(state, payload: { page: string; lead: string }) {
    delete state[payload.page].leads[payload.lead];
  },
  moveLead(state, payload: { lead: string; to: string; from: string }) {
    const oldLead = state[payload.from].leads[payload.lead];
    const newLead = new Lead(oldLead.id, payload.to, []);
    oldLead.relationships.forEach((relationship: string) => {
      newLead.relationships.push(relationship);
    });
    delete state[payload.from].leads[payload.lead];
    state[payload.to].leads[payload.lead] = newLead;
  },
  */
  //Relationship Linking
  /*
  linkRelationship(
    state,
    payload: { page: string; lead: string; relationship: string }
  ) {
    state[payload.page].leads[payload.lead].relationships.push(
      payload.relationship
    );
  },
  unlinkRelationship(
    state,
    payload: { page: string; lead: string; relationship: string }
  ) {
    state[payload.page].leads[payload.lead].relationships.splice(
      state[payload.page].leads[payload.lead].relationships.indexOf(
        payload.relationship
      ),
      1
    );
  },
  */

  /* Metadata */
  update(
    state,
    payload: {
      id: string;
      name?: string;
      icon?: string;
      "required-properties"?: any;
      views?: any;
    }
  ) {
    if (!payload.id) throw "No page passed to page update";

    if (payload["name"] !== undefined) state[payload.id].name = payload["name"];
    if (payload["icon"] !== undefined) state[payload.id].icon = payload["icon"];
    /*
    if (payload["required-properties"] !== undefined)
      payload["required-properties"].forEach((prop: PropertyRule) => {
        state[payload.id].properties[prop.id] = prop;
      });


    if (payload["views"] !== undefined) {
      state[payload.id].views = payload["views"].map((view: any) => {
        return PageView.load(view);
      });
    }
    */
  },
  setName(state, payload: { page: string; name: string }) {
    state[payload.page].name = payload.name;
  },
  setIcon(state, payload: { page: string; icon: string }) {
    state[payload.page].icon = payload.icon;
  },
  setCover(state, payload: { page: string; cover: string }) {
    state[payload.page].cover = payload.cover;
  },

  /* Page Management */
  init(state, payload: Page) {
    state[payload.id] = payload;
  },
  setParent(state, payload: { parent: string; child: string }) {
    state[payload.child].parent = payload.parent;
  },
  delete(state, payload: string) {
    if (state[payload].parent.length > 0)
      state[state[payload].parent].children.delete(payload);
    delete state[payload];
  },
  unload(state) {
    state = {};
  },

  /* View Management */
  /*
  addView(state, payload: { page: string; view: PageView }) {
    state[payload.page].views.push(payload.view);
  },
  setView(state, payload: { page: string; index: number; view: PageView }) {
    state[payload.page].views[payload.index] = payload.view;
  },
  deleteView(state, payload: { page: string; view: number }) {
    state[payload.page].views.splice(payload.view, 1);
  },
  setTimeframe(
    state,
    payload: { page: string; view: number; timeframe: Timeframe }
  ) {
    (state[payload.page].views[payload.view] as TimelineView).timeframe =
      payload.timeframe;
  },

  setQuery(state, payload: { page: string, query: string }) {
    (state[payload.page] as Inbox).query = payload.query;
  },
  promote(state, payload: { lead: string, view: number, board: string }) {
    if((state[payload.board].views[payload.view] as BoardView).promotions[payload.lead]) {
      (state[payload.board].views[payload.view] as BoardView).promotions[payload.lead].push(new Date())
    } else {
      (state[payload.board].views[payload.view] as BoardView).promotions[payload.lead] = [new Date()]
    }
  },
  demote(state, payload: { lead: string, view: number, board: string }) {
    (state[payload.board].views[payload.view] as BoardView).promotions[payload.lead].pop()
  },
  */

  /* Properties */
  /*
  loadProperty(state, payload: PropertyRule) {
    state[payload.page].properties[payload.id] = payload;
  },
  setPropertyKey(
    state,
    payload: { page: string; property: string; key: string }
  ) {
    state[payload.page].properties[payload.property].key = payload.key;
  },
  setPropertyColor(
    state,
    payload: { page: string; property: string; color: PalletColor }
  ) {
    state[payload.page].properties[payload.property].color = payload.color;
  },
  setPropertyType(
    state,
    payload: { page: string; property: string; type: PropertyType }
  ) {
    state[payload.page].properties[payload.property].type = payload.type;
  },
  deleteProperty(state, payload: { page: string; property: string }) {
    delete state[payload.page].properties[payload.property];
  },
  */

  /* Leads */
  /*
  updateLead(state, payload: any) {
    if (!payload.parent) throw "No page passed to lead update";
    if (!payload.lead) throw "No lead passed to lead update";

    ["name"].forEach((key: string) => {
      if (payload[key])
        (state[payload.parent].leads[payload.lead] as any)[key] = payload[key];
    });
  },
  setLeadName(state, payload: { page: string; lead: string; name: string }) {
    state[payload.page].leads[payload.lead].name = payload.name;
  },
  */
};

const actions: ActionTree<PageState, RootState> = {
  /* State */
  addPage(
    { commit },
    payload: { parent: string; child: string }
  ): Promise<string> {
    return new Promise((resolve) => {
      commit("addPage", payload);
      resolve(payload.child);
    });
  },
  removePage(
    { commit },
    payload: { parent: string; child: string }
  ): Promise<string> {
    return new Promise((resolve) => {
      commit("removePage", payload);
      resolve(payload.child);
    });
  },
  //Materials
  /*
  addMaterial({ commit, state }, payload: { page: string; material: string }) {
    if (!state[payload.page].materials.has(payload.material))
      commit("addMaterial", payload);
  },
  removeMaterial({ commit }, payload: { page: string; material: string }) {
    commit("removeMaterial", payload);
  },
  */
  //leads
  /*
  addLead({ commit, rootGetters }, payload: Lead): Promise<Lead> {
    return new Promise((resolve, reject) => {

      payload.id = genId(payload.page, "lead");
      commit("addLead", payload);
      resolve(payload);
      fetch(`https://velocity.keryx.workers.dev/lead?page_id=${payload.page}`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + rootGetters["user/getAuth"].id_token,
        },
        body: JSON.stringify({
          id: payload.id,
          name: payload.name,
        }),
      }).then((response) => {
        if (response.status != 200) {
          response.text().then((result) => {
            console.warn(result);
            reject(result);
          });
        }
      });
    });
  },
  removeLead(
    { commit, dispatch, rootGetters },
    payload: { page: string; lead: string }
  ) {
    commit("removeLead", payload);
    dispatch(
      "util/postToSaving",
      JSON.stringify({
        url: "pages/deleteLead",
        auth: rootGetters["user/getAuth"],
        payload: payload.lead,
      }),
      { root: true }
    );
  },
  moveLead(
    { commit, dispatch, state, rootGetters },
    payload: { lead: string; from: string; to: string }
  ): Promise<Lead> {
    return new Promise((resolve) => {
      Promise.all(
        state[payload.from].leads[payload.lead].relationships.map(
          (relationship: string) => {
            return dispatch(
              "relationships/auditRules",
              { relationship: relationship, page: payload.to },
              { root: true }
            );
          }
        )
      ).then(() => {
        commit("moveLead", {
          lead: payload.lead,
          from: payload.from,
          to: payload.to,
        });
        resolve(state[payload.to].leads[payload.lead]);
        dispatch(
          "util/postToSaving",
          JSON.stringify({
            url: "pages/moveLead",
            auth: rootGetters["user/getAuth"],
            payload: {
              lead: payload.lead,
              to: payload.to,
              from: payload.from,
            },
          }),
          { root: true }
        );
      });
    });
  },
  linkRelationship(
    { commit, dispatch, rootGetters },
    payload: { page: string; lead: string; relationship: string }
  ) {
    dispatch(
      "relationships/auditRules",
      { relationship: payload.relationship, page: payload.page },
      { root: true }
    ).then(() => {
      commit("linkRelationship", payload);
      dispatch(
        "util/postToSaving",
        JSON.stringify({
          url: "pages/linkRelationship",
          auth: rootGetters["user/getAuth"],
          payload: {
            page: payload.page,
            lead: payload.lead,
            relationship: payload.relationship,
          },
        }),
        { root: true }
      );
    });
  },
  unlinkRelationship(
    { commit, dispatch, rootGetters },
    payload: { page: string; lead: string; relationship: string }
  ) {
    commit("unlinkRelationship", payload);
    dispatch(
      "util/postToSaving",
      JSON.stringify({
        url: "pages/unlinkRelationship",
        auth: rootGetters["user/getAuth"],
        payload: {
          page: payload.page,
          lead: payload.lead,
          relationship: payload.relationship,
        },
      }),
      { root: true }
    );
  },
  */

  /* Collaborative Functions */
  _update({ commit }, payload: any) {
    commit("update", payload);
  },
  _create({ commit, dispatch }, payload: any) {
    if (!state[payload.id]) {
      //check if page has already been created
      const page = new Page(payload.id, payload.parent, payload.pages);
      page.name = payload.name;
      page.icon = payload.icon;
      page.cover = payload.cover;
      commit("init", page);
      if (payload.parent) {
        commit("addPage", {
          parent: payload.parent,
          child: payload.id,
        });
      } else {
        dispatch("workspace/addPage", payload.id, { root: true });
      }
    }
  },
  _delete({ commit, dispatch }, payload: any) {
    if (state[payload.id]) {
      //check if page has already been deleted

      if (state[payload.id].parent.length > 0) {
        dispatch("removePage", {
          parent: state[payload.id].parent,
          child: payload.id,
        });
      } else {
        dispatch("workspace/removePage", payload.id, { root: true });
      }
      commit("delete", payload.id);
    }
  },
  /*
  _updateLead({ commit }, payload: any) {
    commit("updateLead", payload);
  },
  _addLead({ commit, state }, payload: any) {

    if (!state[payload.parent].leads[payload.id]) {
      const lead = new Lead(payload.id, payload.parent, payload.relationships);
      lead.name = payload.name;
      commit("addLead", lead);
    }
  },
  _removeLead({ commit, state }, payload: any) {
    if (!state[payload.parent].leads[payload.id]) {
      commit("removeLead", { page: payload.parent, lead: payload.lead });
    }
  },
  _linkLead({ commit, state }, payload: any) {
    if (
      !state[payload.page].leads[payload.id].relationships.includes(
        payload.relationship
      )
    ) {
      commit("linkRelationship", {
        page: payload.page,
        lead: payload.id,
        relationship: payload.relationship,
      });
    }
  },
  _unlinkLead({ commit }, payload: any) {
    if (
      state[payload.parent].leads[payload.id].relationships.includes(
        payload.relationship
      )
    ) {
      commit("unlinkRelationship", {
        page: payload.parent,
        lead: payload.id,
        relationship: payload.relationship,
      });
    }
  },
  */

  /* Metadata */
  setName({ commit }, payload: { page: string; name: string }) {
    commit("setName", payload);
  },
  setIcon({ commit }, payload: { page: string; icon: string }) {
    commit("setIcon", payload);
  },
  setContent({ commit }, payload: { page: string; content: string }) {
    commit("setContent", payload);
  },

  /* Page Management */
  init({ commit, dispatch, rootGetters }, payload: string): Promise<Page> {
    return new Promise<Page>((resolve, reject) => {
      fetch(`https://velocity.keryx.workers.dev/page?id=${payload}`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + rootGetters["user/getAuth"].id_token,
        },
      }).then((response) => {
        if (response.status < 300) {
          response.json().then((result) => {
            const parentTypeRegex =
              /^(?<type>(workspaces)|(pages))\/(?<id>[\w-]+)$/;
            const parentMatch = result.parent[0].match(parentTypeRegex);
            let parent;
            if (parentMatch.groups.type == "workspaces") parent = "";
            else parent = parentMatch.groups.id;

            const page = new Page(payload, parent, []);

            page.name = result.name;
            page.icon = result.icon;
            page.cover = result.cover;
            commit("init", page);
            resolve(page);

            // add pages & materials as thye load
            result.leads.forEach((lead: string) => {
              fetch(`https://velocity.keryx.workers.dev/lead?id=${lead}`, {
                method: "GET",
                headers: {
                  Authorization:
                    "Bearer " + rootGetters["user/getAuth"].id_token,
                },
              }).then((response) => {
                response.json().then((result) => {
                  commit("setLeadName", {
                    page: payload,
                    lead: lead,
                    name: result.name,
                  });
                  result.relationships.forEach((relationship: string) => {
                    commit("linkRelationship", {
                      page: payload,
                      lead: lead,
                      relationship: relationship,
                    });
                  });
                });
              });
            });
            result.pages.forEach((child: string) => {
              dispatch("init", child).then((childPage: Page) => {
                commit("addPage", { parent: payload, child: childPage.id });
              });
            });
            result.materials.forEach((material: string) => {
              dispatch(
                "materials/load",
                { page: payload, material: material },
                { root: true }
              ).then((childMaterial) => {
                commit("addMaterial", {
                  page: payload,
                  material: childMaterial.id,
                });
              });
            });
          });
        } else {
          response.text().then((result) => {
            console.error(result);
            reject(result);
          });
        }
      });
    });
  },
  create(
    { commit, dispatch, rootGetters, state },
    payload: Page
  ): Promise<Page> {
    return new Promise((resolve, reject) => {
      payload.id = genId(rootGetters["workspace/getId"], "page");

      commit("init", payload);
      resolve(payload);

      if (payload.parent == "") {
        dispatch("workspace/addPage", payload.id, { root: true });
      } else {
        dispatch("addPage", {
          parent: payload.parent,
          child: payload.id,
        });
      }
      fetch(
        `https://velocity.keryx.workers.dev/page?workspace_id=${
          rootGetters["workspace/getId"]
        }${payload.parent.length > 0 ? "&page_id=" + payload.parent : ""}`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + rootGetters["user/getAuth"].id_token,
          },
          body: JSON.stringify({
            id: payload.id,
            name: payload.name,
            icon: payload.icon,
            views: [],
          }),
        }
      ).catch((error) => {
        console.warn(error);
        reject(error);
      });
    });
  },
  setParent(
    { commit },
    payload: { parent: string; child: string }
  ): Promise<string> {
    return new Promise((resolve) => {
      commit("setParent", payload);
      resolve(payload.parent);
    });
  },
  delete(
    { commit, dispatch, state, rootGetters, getters },
    payload: string
  ): Promise<Page> {
    return new Promise((resolve) => {
      const page = state[payload];
      if (page.parent == "") {
        dispatch("workspace/removePage", payload, { root: true });
      } else {
        commit("removePage", { parent: page.parent, page: payload });
      }
      page.children.forEach((child: string) => {
        dispatch("delete", child);
      });
      commit("delete", payload);
      resolve(page);
      dispatch(
        "util/postToSaving",
        JSON.stringify({
          url: "pages/delete",
          auth: rootGetters["user/getAuth"],
          payload: {
            page: payload,
          },
        }),
        { root: true }
      );
    });
  },
  setCover(
    { commit, rootGetters },
    payload: { page: string; cover: string }
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      commit("setCover", payload);
      fetch(
        `https://velocity.keryx.workers.dev/cover?page_id=${payload.page}&file_id=${payload.cover}`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + rootGetters["user/getAuth"].id_token,
          },
        }
      ).then((response) => {
        if (response.status != 200) {
          response.text().then((result) => {
            console.warn(result);
            reject(payload.cover);
          });
        } else {
          response.text().then((result) => {
            resolve(payload.cover);
          });
        }
      });
    });
  },
  deleteCover(
    { commit, dispatch, state, rootGetters },
    payload: string
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      const oldCover = state[payload].cover;
      commit("setCover", { page: payload, cover: null });
      fetch(`https://velocity.keryx.workers.dev/cover?page_id=${payload}`, {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + rootGetters["user/getAuth"].id_token,
        },
      });
      dispatch("util/deleteFile", oldCover, { root: true });
    });
  },

  /* Loading */
  unload({ commit, state }, payload: string): Promise<string> {
    return new Promise((resolve) => {
      if (state[payload]) {
        commit("unload", payload);
      }
      resolve(payload);
    });
  },
  clear({ dispatch, state }) {
    Object.keys(state).forEach((key: string) => {
      dispatch("unload", key);
    });
  },

  /* View Management */
  /*
  addView({ commit }, payload: { page: string; view: PageView }) {
    commit("addView", payload);
  },
  setViewType(
    { commit },
    payload: { page: string; view: string; type: PageType }
  ) {
    const newView = PageView.createDefault(payload.type);
    commit("setView", {
      page: payload.page,
      index: payload.view,
      view: newView,
    });
  },
  deleteView({ commit }, payload: { page: string; view: number }) {
    commit("deleteView", payload);
  },
  setTimeframe({ commit }, payload: { page: string; timeframe: Timeframe }) {
    commit("setTimeframe", payload);
  },
  setQuery({ commit }, payload: { page: string; query: string }) {
    commit("setQuery", payload);
  },
  */

  /* Properties */
  /*
  addProperty(
    { commit, dispatch, getters },
    payload: PropertyRule
  ): Promise<PropertyRule> {
    return new Promise((resolve) => {
      if (payload.id == "") payload.id = genId(payload.page, "property");
      Promise.all(
        getters["getLeads"](payload.page).map((lead: Lead) => {
          return Promise.all(
            lead.relationships.map((relationship: string) => {
              return dispatch(
                "relationships/auditRule",
                { relationship: relationship, property: payload },
                { root: true }
              );
            })
          );
        })
      ).then(() => {
        commit("loadProperty", payload);
        resolve(payload);
        dispatch("save", { [payload.page]: ["properties"] });
      });
    });
  },
  setPropertyKey(
    { commit, dispatch, getters, state },
    payload: { page: string; property: string; key: any }
  ) {
    commit("setPropertyKey", payload);
    getters["getLeads"](payload.page).forEach((lead: Lead) => {
      lead.relationships.forEach((relationship: string) => {
        dispatch(
          "relationships/auditRule",
          {
            relationship: relationship,
            property: state[payload.page].properties[payload.property],
          },
          { root: true }
        );
      });
    });
  },
  setPropertyColor(
    { commit, dispatch, getters, state },
    payload: { page: string; property: string; color: PalletColor }
  ) {
    commit("setPropertyColor", payload);
    getters["getLeads"](payload.page).forEach((lead: Lead) => {
      lead.relationships.forEach((relationship: string) => {
        dispatch(
          "relationships/auditRule",
          {
            relationship: relationship,
            property: state[payload.page].properties[payload.property],
          },
          { root: true }
        );
      });
    });
  },
  setPropertyType(
    { commit, dispatch, getters, state },
    payload: { page: string; property: string; type: PropertyType }
  ) {
    commit("setPropertyType", payload);
    getters["getLeads"](payload.page).forEach((lead: Lead) => {
      lead.relationships.forEach((relationship: string) => {
        dispatch(
          "relationships/auditRule",
          {
            relationship: relationship,
            property: state[payload.page].properties[payload.property],
          },
          { root: true }
        );
      });
    });
  },
  deleteProperty(
    { commit, dispatch },
    payload: { page: string; property: string }
  ) {
    commit("deleteProperty", payload);
    dispatch("save", { [payload.page]: ["properties"] });
  },
  */

  /* Clearing */
  /*
  clearRelationship(
    { dispatch, getters },
    payload: { page: string; relationship: string }
  ): Promise<any> {
    return Promise.all(
      getters["getLeads"](payload.page).map((lead: Lead) => {
        if (lead.relationships.includes(payload.relationship)) {
          return dispatch("unlinkRelationship", {
            page: lead.page,
            lead: lead.id,
            relationship: payload.relationship,
          });
        }
      })
    );
  },
  */

  /* Saving */
  save(
    { state, dispatch, rootGetters },
    payload: {
      [page: string]: Array<"name" | "icon" | "properties" | "views">;
    }
  ) {
    Object.keys(payload).forEach((page) => {
      dispatch(
        "util/postToSaving",
        JSON.stringify({
          url: "pages/save",
          auth: rootGetters["user/getAuth"],
          payload: {
            page: page,
            ...(payload[page].includes("name") && {
              name: state[page].name,
            }),
            ...(payload[page].includes("icon") && {
              icon: state[page].icon,
            }),
          },
        }),
        { root: true }
      );
    });
  },

  /* Leads */
  // Management
  /*
  setLeadName(
    { commit },
    payload: { page: string; lead: string; name: string }
  ) {
    commit("setLeadName", payload);
  },
  */
  // saveing
  /*
  saveLead(
    { dispatch, state, rootGetters },
    payload: { [page: string]: { [lead: string]: Array<"name"> } }
  ) {
    Object.keys(payload).forEach((page: string) => {
      Object.keys(payload[page]).forEach((lead: string) => {
        dispatch(
          "util/postToSaving",
          JSON.stringify({
            url: "pages/saveLead",
            auth: rootGetters["user/getAuth"],
            payload: {
              page: page,
              lead: lead,
              ...(payload[page][lead].includes("name") && {
                name: state[page].leads[lead].name,
              }),
            },
          }),
          { root: true }
        );
      });
    });
  },
  */
};

export const pages: Module<PageState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
